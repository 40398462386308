<script setup>
import { isFn, arrayNotEmpty, lastInList } from "@/utils/functions";

const props = defineProps({
  headers: {
    type: Array,
    default: null,
  },
  items: {
    type: Array,
    default: null,
  },
  headClasses: {
    type: String,
    default: "",
  },
  headStyles: {
    type: Object,
    default: () => ({}),
  },
  bodyClasses: {
    type: String,
    default: "",
  },
  bodyStyles: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  emptyText: {
    type: String,
    default: "No items found",
  },
  dense: {
    type: Boolean,
    default: false,
  },
});

function getCellValue(header, item) {
  if (isFn(header.fn)) {
    return header.fn(item);
  } else {
    return item?.[header.value];
  }
}
</script>

<template>
  <div v-if="$mobileBreakpoint">
    <template v-if="arrayNotEmpty(items)">
      <v-card
        v-for="(item, index) in items"
        :key="index"
        outlined
        :class="{
          'mt-4': index !== 0,
        }"
      >
        <v-card-title
          v-if="$scopedSlots.itemTitle"
          :class="{ 'text-body-1 font-weight-bold': $mobileBreakpoint }"
        >
          <slot name="itemTitle" :item="item"></slot>
        </v-card-title>

        <v-simple-table :dense="dense">
          <template #default>
            <tbody>
              <tr v-for="(header, index) in headers" :key="index">
                <td class="text-caption">
                  <slot :name="`header.${header.value}`" :header="header">
                    {{ header.text }}
                  </slot>
                </td>
                <td class="text-caption">
                  <slot :name="`item.${header.value}`" :item="item">
                    {{ getCellValue(header, item) }}
                  </slot>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </template>
    <div v-else class="text-center text-body-2">
      {{ loading ? "Loading" : emptyText }}
    </div>
  </div>

  <v-simple-table v-else :dense="dense">
    <template #default>
      <thead
        :class="['table-header-text', headClasses]"
        :style="[{ fontSize: '12px' }, headStyles]"
      >
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            :style="{ color: 'inherit', fontSize: 'inherit' }"
            :class="{
              'text-md-center': header.action === true,
              'pr-0': lastInList(index, headers),
              [header.textAlign]: !!header.textAlign,
            }"
          >
            <slot :name="`header.${header.value}`" :header="header">
              {{ header.text }}
            </slot>
          </th>
        </tr>
      </thead>

      <tbody :class="[bodyClasses]" :style="[{ fontSize: '14px' }, bodyStyles]">
        <template v-if="arrayNotEmpty(items)">
          <tr v-for="(item, rowIndex) in items" :key="rowIndex">
            <td
              v-for="(header, colIndex) in headers"
              :key="colIndex"
              :style="{ color: 'inherit', fontSize: 'inherit' }"
              :class="{
                'text-md-center': header.action === true,
                'pr-0': lastInList(colIndex, headers),
                [header.textAlign]: !!header.textAlign,
              }"
            >
              <slot :name="`item.${header.value}`" :item="item">
                {{ getCellValue(header, item) }}
              </slot>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="100%" align="center">
            {{ loading ? "Loading... Please Wait" : emptyText }}
          </td>
        </tr>
        <slot name="additionalRows"></slot>
      </tbody>
    </template>
  </v-simple-table>
</template>
