var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$mobileBreakpoint)?_c('div',[(_setup.arrayNotEmpty(_vm.items))?_vm._l((_vm.items),function(item,index){return _c('v-card',{key:index,class:{
        'mt-4': index !== 0,
      },attrs:{"outlined":""}},[(_vm.$scopedSlots.itemTitle)?_c('v-card-title',{class:{ 'text-body-1 font-weight-bold': _vm.$mobileBreakpoint }},[_vm._t("itemTitle",null,{"item":item})],2):_vm._e(),_c('v-simple-table',{attrs:{"dense":_vm.dense},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.headers),function(header,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-caption"},[_vm._t(`header.${header.value}`,function(){return [_vm._v(" "+_vm._s(header.text)+" ")]},{"header":header})],2),_c('td',{staticClass:"text-caption"},[_vm._t(`item.${header.value}`,function(){return [_vm._v(" "+_vm._s(_setup.getCellValue(header, item))+" ")]},{"item":item})],2)])}),0)]},proxy:true}],null,true)})],1)}):_c('div',{staticClass:"text-center text-body-2"},[_vm._v(" "+_vm._s(_vm.loading ? "Loading" : _vm.emptyText)+" ")])],2):_c('v-simple-table',{attrs:{"dense":_vm.dense},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{class:['table-header-text', _vm.headClasses],style:([{ fontSize: '12px' }, _vm.headStyles])},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,class:{
            'text-md-center': header.action === true,
            'pr-0': _setup.lastInList(index, _vm.headers),
            [header.textAlign]: !!header.textAlign,
          },style:({ color: 'inherit', fontSize: 'inherit' })},[_vm._t(`header.${header.value}`,function(){return [_vm._v(" "+_vm._s(header.text)+" ")]},{"header":header})],2)}),0)]),_c('tbody',{class:[_vm.bodyClasses],style:([{ fontSize: '14px' }, _vm.bodyStyles])},[(_setup.arrayNotEmpty(_vm.items))?_vm._l((_vm.items),function(item,rowIndex){return _c('tr',{key:rowIndex},_vm._l((_vm.headers),function(header,colIndex){return _c('td',{key:colIndex,class:{
              'text-md-center': header.action === true,
              'pr-0': _setup.lastInList(colIndex, _vm.headers),
              [header.textAlign]: !!header.textAlign,
            },style:({ color: 'inherit', fontSize: 'inherit' })},[_vm._t(`item.${header.value}`,function(){return [_vm._v(" "+_vm._s(_setup.getCellValue(header, item))+" ")]},{"item":item})],2)}),0)}):_c('tr',[_c('td',{attrs:{"colspan":"100%","align":"center"}},[_vm._v(" "+_vm._s(_vm.loading ? "Loading... Please Wait" : _vm.emptyText)+" ")])]),_vm._t("additionalRows")],2)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }