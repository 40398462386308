<script setup>
import { actions } from "@/utils/constants";
import { computed } from "vue";

const props = defineProps({
  action: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  inTable: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const buttonProperties = computed(() => {
  let color = null;
  let text = null;
  let icon = null;
  switch (props.action) {
    case actions.view:
      color = "primary";
      text = "View";
      icon = "mdi-eye";
      break;
    case actions.downloadBill:
      color = "secondary";
      text = "Bill";
      icon = "mdi-download-circle";
      break;
    case actions.downloadReceipt:
      color = "success";
      text = "Receipt";
      icon = "mdi-download-circle";
      break;
    case actions.downloadVehicleSticker:
      color = "secondary";
      text = props.inTable ? "Sticker" : "Dowload Sticker";
      icon = "mdi-download-circle";
      break;
    case actions.downloadVehicleList:
      color = "secondary";
      text = "Export to PDF";
      icon = "mdi-download-circle";
      break;
    case actions.pay:
      color = "success";
      text = "Pay";
      icon = "mdi-cash";
      break;
    case actions.canSelect:
      color = "primary";
      text = "Select";
      icon = "mdi-checkbox-blank-outline";
      break;
    case actions.canUnselect:
      color = "success";
      text = "Selected";
      icon = "mdi-checkbox-marked-outline";
      break;
    case actions.renewPermit:
      color = "secondary";
      text = "Renew";
      icon = "mdi-autorenew";
      break;
    case actions.payPropertyRates:
      color = "secondary";
      text = "Pay Rates";
      icon = "mdi-cash";
      break;
    case actions.removeItem:
      color = "error";
      text = "Remove";
      icon = "mdi-delete";
      break;
  }
  color = props.color ?? color;
  text = props.text ?? text;
  return {
    text,
    color,
    icon,
  };
});
</script>

<template>
  <div>
    <v-btn
      :small="inTable"
      depressed
      :color="buttonProperties.color"
      class="text-none white--text"
      :loading="loading"
      @click="$emit('click',$event)"
    >
      <span>{{ buttonProperties.text }}</span>
      <v-icon right>{{ buttonProperties.icon }}</v-icon>
    </v-btn>
  </div>
</template>
